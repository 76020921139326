import { useState } from "react";
import { useLocation } from "react-router-dom";

import PageHeader from "../../../../@core/components/PageHeader";
import CustomTabs from "../../../components/CustomTabs";
import { Button } from "reactstrap";
import { MessageSquare } from "react-feather";
import ForecastTab from "./ForecastTab";
import SimulationModel from "./SimulationModel";
import SidebarDashboard from "../../../Dashboard/Sidebar";
import { v4 as uuidv4 } from "uuid";
import ForecastGraph from "./ForecastGraph";
import { runSimulatioForecastHandler } from "../../../../services/cashflowMonitoring";
import './index.scss';
import { statusCode } from "../../../../utility/constants/utilObject";


const CashFlowForecast = () => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [multiLoading, setMultiloading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [activeStep, setActiveStep] = useState(0);

    const [forecastGroupKey, setForecastGroupKey] = useState(0);

    const { state } = location;
    const [receivedData, setReceivedData] = useState(state?.data || '');

    const [isLoading, setIsLoading] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)


    const [isSimulationOpen, setIsSimulationOpen] = useState(false);
    const [tabs, setTabs] = useState([{ name: "Forecast Dashboard", id: null }]);
    // Function to add a new tab dynamically
    const addSimulationTab = (simulationData) => {
        const newTab = {
            name: simulationData.simulationName,
            id: uuidv4()
        };
        setTabs([...tabs, newTab]);
        setActiveTab(tabs.length);  // Activate the newly added tab
        setIsLoading(true)
        fetchSimulatedDataHandler(simulationData)
    };

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    function formatAnalysisDate(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            return `${months[date.getMonth()]} ${date.getFullYear()}`;
        }
    }

    const renderPanelContainer = () => {
        if (multiLoading) {
            return (
                <div style={{ marginTop: "100px", marginBottom: "100px",}}>
                    <MultiStepLoader stepNumber={activeStep} />
                </div>
            )
        } else {
            const currentTab = tabs[activeTab]; // Get the current tab object (with name and id)
            return (
                <ForecastTab  
                    key={receivedData.analytics.simulations?receivedData.analytics.simulations.length:0}
                    receivedData={receivedData || ''}
                    activeSimulation={currentTab}
                    />
            );
        }
    }

    const fetchSimulatedDataHandler = (simulationData) => {

        const payload ={
            session: 1,
            time_period: 24,
            simulation_data: JSON.stringify({
                customer_concentration: simulationData['customerConcentrationRisk'] ? simulationData['customerConcentrationRisk'] : 0,
                supplier_concentration: simulationData['supplierConcentrationRisk'] ? simulationData['supplierConcentrationRisk'] : 0,
                revenue_growth_rate: simulationData['revenueGrowthRate'] ? simulationData['revenueGrowthRate'] : 0,  // Corrected key name
                net_profit_margin: simulationData['profitabilityRisk'] ? simulationData['profitabilityRisk'] : 0,
                gross_margin_decline: simulationData['growthSustainabilityRisk'] ? simulationData['growthSustainabilityRisk'] : 0,
                operating_cash_flow_to_debt: simulationData['marginCompressionRisk'] ? simulationData['marginCompressionRisk'] : 0  // Removed extra single quote
            })
        }
        runSimulatioForecastHandler(payload)
        .then(response => {
            if (response.status == statusCode.HTTP_200_OK) {
                const data = response.data;
                const tempReceivedData = {...receivedData}
                
                if(!tempReceivedData.analytics.simulations){
                    tempReceivedData.analytics['simulations'] = [{
                        name: simulationData.simulationName,
                        forecast: data?.forecast,
                        calculated_risks: data?.simulated_risk
                    }]
                }else{
                    tempReceivedData.analytics['simulations'].push({
                        name: simulationData.simulationName,
                        forecast: data,
                        calculated_risks: data?.simulated_risk
                    })
                }
                console.log('tempReceivedData', tempReceivedData)
                setReceivedData(tempReceivedData)
                setIsLoading(false)
                setForecastGroupKey(forecastGroupKey + 1)
            }
        })
        .catch(error => {
            console.log('Error in fetching data')
        })
    }


    return (
        <div className='container-fluid vh-85'>
            <PageHeader
                pageName={"Cashflow Analysis & Forecasting | Session Id"}
                backButtonEnabled={true}
            />
            <div className="cashflow-forecast">
                <div className='page-header' style={{ border: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'50px' }}>
                    <div className="cashflow-table__content">
                        <h1 className="cashflow-table__title">{receivedData?.company_name || ''}</h1>
                        <p>Analysis of books from {formatAnalysisDate(receivedData?.start_date || '')} - {formatAnalysisDate(receivedData?.end_date || '')}</p>
                    </div>

                    {
                        !multiLoading &&
                        <>
                            <div className='search-container' style={{ minWidth:'400px', display: 'flex', justifyContent: 'flex-end', textAlign: 'end', marginTop: '-10px' }}>
                                <Button color="success" onClick={() => { setIsSimulationOpen(true) }} style={{ marginRight: '5px', fontWeight: '800' }}>
                                    Run Simulation
                                </Button>
                                <Button color="primary" onClick={() => { setSidebarOpen(true) }} style={{ marginRight: '5px', fontWeight: '800' }}>
                                    <MessageSquare /> Ask A Query
                                </Button>
                            </div>
                        </>

                    }
                </div>

                <div className="forecast-tab__graph">
                    {/* <LineCard data={data} options={{ responsive: true }} /> */}
                    <ForecastGraph
                        key={receivedData.analytics.simulations?receivedData.analytics.simulations.length:0}
                        data={receivedData}
                    />
                </div>


                <div className='tab-container'>
                    {
                        !multiLoading &&
                        <CustomTabs
                            tabs={tabs.map(tab => tab.name)} // Render tab names
                            tabStatus={[true, false, false, true]}
                            onTabSelect={setActiveTab}
                            isLoading={isLoading}
                            isCompleted={isCompleted}
                        />
                    }
                </div>
                <div className='page-container no-scroll-bar'
                    style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        paddingTop: '0px',
                        height: '80vh',
                    }}
                >
                    {renderPanelContainer()}
                </div>
                <SimulationModel
                    isOpen={isSimulationOpen}
                    setIsOpen={setIsSimulationOpen}
                    addSimulationTab={addSimulationTab}  // Pass the function to add tabs
                    receivedData={receivedData?.calculated_risks || ''}
                />

                <SidebarDashboard
                    open={sidebarOpen}
                    title="Cashflow Analytics"
                    documentId={"1"}
                    toggleSidebar={() => { setSidebarOpen(false) }} />

            </div>
        </div>
    )
}

export default CashFlowForecast;